const portfolioItems = [
  {
    index: 1,
    title: "Club 405",
    synopsis: "A summary of research around harassment of Virtual AI and Robotic Influencers on Instagram",
    description: "During my time at Rutgers University, I participated in a research project for the Digital Studies department in which we researched harassment of Virtual Influencers on Instagram. We focused the research around the account of a character called '@lilmiquela'. This account claims to be a real being that is robotic, not unlike Sofia the Robot, however it is simply a 3D model of a character created by a company called Brud. Because of the ambiguity of facts surrounding this account it gets a lot of harassment, and we were interested to see what kind of harassment was most common for a virtual being to receive.",
    link: "https://chri55.github.io/club405/",
    previewImageLink: "https://cpt-images.s3.us-east-2.amazonaws.com/club405Demo.png",
    repo: "https://github.com/chri55/club405",
    tags: ["JavaScript", "HTML", "CSS", "Python"],
    slug: "club-405",
  },
  {
    index: 2,
    title: "Rutgers Camden ACM",
    synopsis: "Club website made during my time as Webmaster of the Rutgers ACM Camden Chapter.",
    description: "During my time at Rutgers Camden, I was the first Webmaster for our ACM chapter, which was only a year old. This is the RU-C ACM's very first chapter website, featuring dates for events, pictures taken at our club meetings, and achievements of our members. Currently only the test build is public; the production build will be available in the fall when the new Webmaster for the club takes over.",
    link: "https://acm-dev-website.ue.r.appspot.com/",
    repo: "https://github.com/rutgersacm/ruc-acm",
    previewImageLink: "https://cpt-images.s3.us-east-2.amazonaws.com/ruc-acmDemo.png",
    tags: ["Python", "Flask", "HTML", "CSS", "Javascript", "Google Cloud Services"],
    slug: "rutgers-acm-site",
  },
  {
    index: 3,
    title: "Algorithm Simulator",
    synopsis: "Research on distributed algorithms for mobile agents on a  geometric field.",
    description: "I collaborated on this project during my time as a research assistant at the Rutgers University CCIB. The aim was to build an application to simulate and visualize search-and-evacuate algorithms for mobile agents in a known geometry. We started with simulations on a disk, and from there moved to other regular polygons and the 1-dimensional line. The original was written in Javascript with D3.js, and later we rewrote the backend to utilize Python for the data simulation. (NOTE: The repo for this project is private as it belongs to Rutgers and NSF.)",
    link: "https://sshende.github.io/BotAlgorithms/",
    previewImageLink: "https://cpt-images.s3.us-east-2.amazonaws.com/botAlgDemo.png",
    repo: "#",
    tags: ["JavaScript", "HTML", "CSS", "D3.js", "Python", "Django", "Amazon Web Services"],
    slug: "search-evacuate-simulator",
  },
  {
    index: 4,
    title: "React Mock Webstore",
    synopsis: "Used to learn the basics and advanced concepts of React by making a small mock web-store.",
    description: "While learning React I created a project that revolved around the concepts of building a web store. This mock store currently has the following features: Sign in and out with Google or Email using Firebase Authentication, add to cart feature using pure Redux and redux-persist, styles created using Sass, mock checkout feature created using stripe-checkout api for React, and deployed to Heroku with the Heroku command line interface.",
    link: "https://chri55-crwn-live.herokuapp.com/",
    previewImageLink: "https://cpt-images.s3.us-east-2.amazonaws.com/crwnDemo.png",
    repo: "https://github.com/chri55/crwn",
    tags: ["React", "Redux", "Sass", "Firebase", "Heroku", ],
    slug: "web-store-app",
  },
  {
    index: 5,
    title: "Squid Bounce",
    synopsis: "Pixi.js minigame inspired by Splatoon.",
    description: "To help me learn JavaScript when I first began, I did this as a side project and used a fun little JS game engine called Pixi.js. Currently, it has infinite levels, increasing difficulty, and a point system based on how high you've gotten. In the future I hope to make some custom textures using pixel art for the lava that follows the player. I also plan to implement platforms that interact with the player differently, such as ice for sliding landings and conveyor belts to increase difficulty. Overall, I learned a lot about Pixi.js and JavaScript as a whole.",
    link: "https://chri55.github.io/squid-bounce",
    previewImageLink: "https://cpt-images.s3.us-east-2.amazonaws.com/squidBounceDemo.png",
    repo: "https://github.com/chri55/squid-bounce",
    tags: ["JavaScript", "HTML", "CSS", "Pixi.js"],
    slug: "squid-bounce",
  },
  {
    index: 6,
    title: "WebCalc",
    synopsis: "A vanilla JavaScript web calculator I made as a practice for The Odin Project.",
    description: "As part of the curriculum for The Odin Project, a series of courses about web development, I was asked to make a calculator in JavaScript using no libraries. I learned some very important lessons about how postfix notation is parsed and used in calculations. This currently support multi-operator expressions, decimal point numbers, and the 4 basic math functions.",
    link: "https://chri55.github.io/webcalculator",
    previewImageLink: "https://cpt-images.s3.us-east-2.amazonaws.com/webcalcDemo.png",
    repo: "https://github.com/chri55/webcalculator",
    tags: ["JavaScript", "HTML", "CSS"],
    slug: "web-calculator",
  },
  {
    index: 7,
    title: "Web Developer Portfolio",
    synopsis: "This website. Powered mainly by Gatsby and React.",
    description: "My senior project in my Digital Studies curriculum was to create my own website, from getting and setting up my own URL to hosting the site itself. For my URL, I used Amazon Web Services Route 53 service to buy and manage the domain. This site is also hosted on Netlify. This project was a great intro to React and Gatsby, and I got to learn some other new technology such as PostCSS and GraphQL. I picked a website to feature a blog as well as my portfolio, so I could post my writings from my  Digital Studies classes and my personal research and learning endeavours.",
    link: "#",
    previewImageLink: "https://cpt-images.s3.us-east-2.amazonaws.com/portfolioDemo.png",
    repo: "https://github.com/chri55/portfolio",
    tags: ["JavaScript", "HTML", "CSS", "React", "Gatsby", "PostCSS", "GraphQL", "Amazon Web Services"],
    slug: "portfolio-website",
  },
];

module.exports = portfolioItems;
